import React from "react";
import { Link, useNavigate } from "react-router-dom";
import admin from "../Images/admin.png";
function Error() {
  const num = Date.now();
  return (
    <div className="ab">
      <center>
        <div className="errors">
          {/* <h1 style={{ fontWeight: "bolder", fontSize: "15px" }}>
            Transaction Failed!
          </h1> */}
          <img src={admin} />
          <div style={{ position: "relative", bottom: "30px" }}>
            <p>{num}</p>
          </div>
          <h2
            style={{
              fontSize: "17px",
              fontWeight: "800",
            }}
          >
            Oops!,the wallet connected might not be compatible
          </h2>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Please contact admin/support for authentication with your refrence
            code!
          </p>
          <Link to="/">
            <button className="btn btn-primary intro" style={{ width: "80%" }}>
              Back to home
            </button>
          </Link>
        </div>
      </center>
    </div>
  );
}

export default Error;
