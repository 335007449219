export const Img = [
  {
    name: "Wallet Connect",
    img: require("./assets/walletconnect.png"),
    url: "Crypto Wallet.com",
  },
  { name: "Metamask", img: require("./assets/meta.jpg"), url: "Metamask.com" },
  {
    name: "Trust Wallet",
    img: require("./assets/Tw.jpg"),
    url: "Trust Wallet.com",
  },
  { name: "Crypto.com", img: require("./assets/defi.png"), url: "Defi .com" },
  { name: "Helium", img: require("./assets/helium.png"), url: "Defi .com" },
  {
    name: "Ledger Live",
    img: require("./assets/ledger_live.jpg"),
    url: "Defi .com",
  },
  {
    name: "MYKEY",
    img: require("./assets/mykey.jpg"),
    url: "Defi .com",
  },
  {
    name: "Huobi Wallet",
    img: require("./assets/huobi.jpeg"),
    url: "Defi .com",
  },
  {
    name: "Polygon Wallet",
    img: require("./assets/polygon.jpg"),
    url: "Defi .com",
  },
  {
    name: "Bridge Wallet",
    img: require("./assets/bridge_wallet.jpg"),
    url: "Defi .com",
  },
  {
    name: "GridPlus",
    img: require("./assets/gridplus.jpg"),
    url: "Defi .com",
  },
  { name: "Exodus", img: require("./assets/exodus.png"), url: "Defi .com" },

  {
    name: "Solana Wallet",
    img: require("./assets/solana.png"),
    url: "Crypto Wallet.com",
  },
  {
    name: "Safepal Wallet",
    img: require("./assets/safepal.png"),
    url: "Crypto Wallet.com",
  },
  {
    name: "Wallet.Io",
    img: require("./assets/walletio.png"),
    url: "Crypto Wallet.com",
  },
  {
    name: "Coinbase ",
    img: require("./assets/coinbase.png"),
    url: "Crypto Wallet.com",
  },
  {
    name: "Binance DEX",
    img: require("./assets/binance.jpeg"),
    url: "Crypto Wallet.com",
  },
  {
    name: "Atomic Wallet",
    img: require("./assets/atomic.png"),
    url: "Crypto Wallet.com",
  },
  {
    name: "Polka Dot",
    img: require("./assets/polka.jpeg"),
    url: "Polkadot.com",
  },
  { name: "Maiar", img: require("./assets/maiar.png"), url: "Maiar.com" },
  { name: "Equal ", img: require("./assets/equal.jpeg"), url: "equal.com" },

  { name: "Kyber ", img: require("./assets/keb.jpeg"), url: "Kyber.com" },
  { name: "Ledger ", img: require("./assets/led.jpeg"), url: "Tron.com" },
  { name: "Phantom ", img: require("./assets/pht.jpeg"), url: "Phantom.com" },
  // {
  //   name: "Atomic Wallet",
  //   img: require("./assets/atomic.jpeg"),
  //   url: "Atomic.com",
  // },
  // { name: "MEET.ONE", img: require("./assets/meet.jpeg"), url: "Meet.ONE.com" },
  // {
  //   name: "Rainbow Wallet",
  //   img: require("./assets/rainbow.jpeg"),
  //   url: "rainbowWallet.com",
  // },
  { name: "Ziliqa", img: require("./assets/zill.png"), url: "Ziliqa.com" },
  { name: "Eidoo", img: require("./assets/eid.png"), url: "Ziliqa.com" },
  { name: "BitPay", img: require("./assets/bp.jpeg"), url: "BitaPy.com" },
  {
    name: "imToken",
    img: require("./assets/17_mrrhur.png"),
    url: "imToken.com",
  },
  // {
  //   name: "CYBAVO Wallet",
  //   img: require("./assets/cy.png"),
  //   url: "imToken.com",
  // },
  { name: "Coin98", img: require("./assets/coin98.png"), url: "coin98.com" },
  // {
  //   name: "Huobi Wallet",
  //   img: require("./assets/huobi.jpeg"),
  //   url: "Houbiwallet.com.com",
  // },
  // {
  //   name: "Ledger Live",
  //   img: require("./assets/dger.png"),
  //   url: "Ledgetlive.com",
  // },
  { name: "Tron", img: require("./assets/TRN.jpeg"), url: "Tron.com" },
  // {
  //   name: "Solfare Wallet",
  //   img: require("./assets/solfare.png"),
  //   url: "solfare.com",
  // },
  // { name: "ONTO", img: require("./assets/onto.png"), url: "ONTO.com" },
  { name: "Stepen", img: require("./assets/spen.png"), url: "Stepen.com" },
  {
    name: "Math Wallet",
    img: require("./assets/mathwallet.png"),
    url: "Mathwallet.com",
  },
];
