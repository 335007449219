import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Wallets from "./Pages/Wallets";

import ManualConnect from "./Pages/ManualConnect";
import Error from "./Pages/Error";
function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wallets" element={<Wallets />} />
          <Route path="/connectManually" element={<ManualConnect />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
